<div *ngIf="!loader">
    <div class="img-wrapper">
        <div class="lable-block">
            <span class="lable3" *ngIf="product.new">new</span>
            <span class="lable4" *ngIf="product.sale">on sale</span>
        </div>
        <div class="front">
            <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
                <img *ngIf="product.images.length != 0" [src]="product.images[0].src" [lazyLoad]="product.images[0].src" class="img-fluid lazy-loading" alt="{{product.images[0].alt}}" />
                <img *ngIf="product.images.length == 0" [src]="'assets/images/product/electronics/1.jpg'" [lazyLoad]="'assets/images/product/electronics/1.jpg'" class="img-fluid lazy-loading" />
            </a>
        </div>
        <div class="back" *ngIf="onHowerChangeImage">
            <a [routerLink]="['/shop/product/left/sidebar/', product.id]">
                <img [src]="product.images.length != 0 ? product.images[0].src : 'assets/images/product/electronics/1.jpg'" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
            </a>
        </div>
        <ul class="product-thumb-list" *ngIf="thumbnail">
            <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
                <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
                    <img [lazyLoad]="image.src">
                </a>
            </li>
        </ul>
        <div class="cart-info cart-wrap">
            <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
                <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
                <i class="ti-shopping-cart"></i>
            </a>
            <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
                <i class="ti-heart" aria-hidden="true"></i>
            </a>
            <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
                <i class="ti-search" aria-hidden="true"></i>
            </a>
            <!-- <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
                <i class="ti-reload" aria-hidden="true"></i>
            </a> -->
        </div>
    </div>
    <div class="product-detail">
        <div>
            <!-- <bar-rating [rate]="5" [readOnly]="true"></bar-rating> -->
            <br>
            <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]">
                <h6>{{ product?.title | titlecase }}</h6>
            </a>
            <p>{{ product?.description }}</p>
            <h4>
                {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
                <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
            </h4>
            <ul class="color-variant" *ngIf="Color(product?.variants).length">
                <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                </li>
            </ul>
        </div>
    </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>