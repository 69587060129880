<div class="icon-nav">
    <ul>
        <li class="onhover-div mobile-search search-widgets" id="search-widgets" *ngIf="false">
            <div (click)="searchToggle()">
                <img src="assets/images/icon/search.png" class="img-fluid" alt="">
                <i class="ti-search"></i>
            </div>
            <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
                <div>
                    <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
                    <div class="overlay-content">
                        <div class="container">
                            <div class="row">
                                <div class="col-xl-12">
                                    <form>
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputPassword1" placeholder="Search a Product">
                                        </div>
                                        <button type="submit" class="btn btn-primary"><i class="fa fa-search"></i></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="onhover-div mobile-setting" *ngIf="false">
            <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
                <i class="ti-settings"></i></div>
            <div class="show-div setting">
                <h6>language</h6>
                <ul>
                    <li *ngFor="let language of languages">
                        <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
                    </li>
                </ul>
                <h6>currency</h6>
                <ul class="list-inline">
                    <li *ngFor="let currency of currencies">
                        <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
                    </li>
                </ul>
            </div>
        </li>
        <li class="onhover-div mobile-cart">
            <div>
                <a [routerLink]="['/shop/cart']">
                    <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
                    <i class="ti-shopping-cart"></i>
                </a>
            </div>
            <span class="cart_qty_cls">{{ products.length }}</span>
            <ul class="show-div shopping-cart" *ngIf='!products.length'>
                <h5>Your cart is currently empty.</h5>
            </ul>
            <ul class="show-div shopping-cart" *ngIf='products.length'>
                <li *ngFor="let product of products">
                    <div class="media">
                        <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                            <img class="mr-3" [src]="product?.images[0].src" [alt]="product?.images[0].alt">
                        </a>
                        <div class="media-body">
                            <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
                                <h4>{{ product?.title }}</h4>
                            </a>
                            <h4>
                                <span>
                  {{ product?.quantity }} x 
                  {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="close-circle" (click)="removeItem(product)">
                        <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div>
                </li>
                <li>
                    <div class="total">
                        <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
                    </div>
                </li>
                <li>
                    <div class="buttons">
                        <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
                        <a [routerLink]="['/shop/checkout']" class="checkout">checkout</a>
                    </div>
                </li>
            </ul>
        </li>
    </ul>
</div>